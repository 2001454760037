/*********************************************
* Main Page
*********************************************/

.navbar-ap {
    margin-bottom: 0;
}

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #EEE;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/*********************************************
* Login / Auth Pages
*********************************************/
.container .row .panel-body .form-group .apply-textarea {
    resize: none;
}

/*********************************************
* Sidebar Styles
*********************************************/
.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav > .active {
    outline: none;
    border-color: #587272;
    background-color: #2f4f4f;
    box-shadow: 0 0 10px #97a7a7;
}

.sidebar-nav > .active > a {
    color: white;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}

@media (min-width: 768px) {
    #wrapper {
        padding-left: 250px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

/*********************************************
* Terms and Conditions
*********************************************/
.tos-title {
    text-align: center;
}

/*********************************************
* Padding and Margin Styles
*********************************************/
.p-xxs {
    padding: 5px;
}

.p-xs {
    padding: 10px;
}

.p-sm {
    padding: 15px;
}

.p-m {
    padding: 20px;
}

.p-md {
    padding: 25px;
}

.p-lg {
    padding: 30px;
}

.p-xl {
    padding: 40px;
}

.m-xxs {
    margin: 2px 4px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-n-xxs {
    margin-left: -1px;
}

.m-l-n-xs {
    margin-left: -5px;
}

.m-l-n-sm {
    margin-left: -10px;
}

.m-l-n {
    margin-left: -15px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-l-n-lg {
    margin-left: -30px;
}

.m-l-n-xl {
    margin-left: -40px;
}

.m-t-none {
    margin-top: 0;
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-n-xxs {
    margin-top: -1px;
}

.m-t-n-xs {
    margin-top: -5px;
}

.m-t-n-sm {
    margin-top: -10px;
}

.m-t-n {
    margin-top: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-t-n-lg {
    margin-top: -30px;
}

.m-t-n-xl {
    margin-top: -40px;
}

.m-r-none {
    margin-right: 0;
}

.m-r-xxs {
    margin-right: 1px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-r-n-xxs {
    margin-right: -1px;
}

.m-r-n-xs {
    margin-right: -5px;
}

.m-r-n-sm {
    margin-right: -10px;
}

.m-r-n {
    margin-right: -15px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-r-n-lg {
    margin-right: -30px;
}

.m-r-n-xl {
    margin-right: -40px;
}

.m-b-none {
    margin-bottom: 0;
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-n-xxs {
    margin-bottom: -1px;
}

.m-b-n-xs {
    margin-bottom: -5px;
}

.m-b-n-sm {
    margin-bottom: -10px;
}

.m-b-n {
    margin-bottom: -15px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-b-n-lg {
    margin-bottom: -30px;
}

.m-b-n-xl {
    margin-bottom: -40px;
}

.space-15 {
    margin: 15px 0;
}

.space-20 {
    margin: 20px 0;
}

.space-25 {
    margin: 25px 0;
}

.space-30 {
    margin: 30px 0;
}

.myGrid {
    width: 100%;
}

/*********************************************
* Misc
*********************************************/
.no-visibility {
    visibility: hidden;
}

.btn-white {
    background-color: white;
    border-color: rgb(204, 208, 210);
}

/*********************************************
* Spinner
*********************************************/
.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #2f4f4f;
    background: -moz-linear-gradient(left, #2f4f4f 10%, rgba(47, 79, 79, 0) 42%);
    background: -webkit-linear-gradient(left, #2f4f4f 10%, rgba(47, 79, 79, 0) 42%);
    background: -o-linear-gradient(left, #2f4f4f 10%, rgba(47, 79, 79, 0) 42%);
    background: -ms-linear-gradient(left, #2f4f4f 10%, rgba(47, 79, 79, 0) 42%);
    background: linear-gradient(to right, #2f4f4f 10%, rgba(47, 79, 79, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: #2f4f4f;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader:after {
    background: #f5f8fa;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*********************************************
* Ag-Grid
*********************************************/

.ag-floating-bottom-container {
    font-weight: 700;
}

.ag-floating-bottom-container .ag-cell {
    margin-top: 8px;
}

.ag-tr-style .ag-cell-no-focus {
    text-align: center;
}

span.parent-node {
    font-weight: 600;
}

span.leaf-node {
    padding-left: 9px;
}

div.ag-root {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.ag-tr-style {
    line-height: 1.4;
    font-family: "Helvetica Neue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.ag-tr-style img {
    vertical-align: middle;
    border: 0px;
}

.ag-tr-style .ag-root {
    border: 1px solid #ddd;
    font-size: 12px;
}

.ag-tr-style .ag-row-group-cell {
    background: linear-gradient(white, lightgrey);
    border: 1px solid #ddd;
    padding: 2px;
}

.ag-tr-style .ag-row-group-cell-ghost {
    opacity: 0.5;
}

.ag-tr-style .ag-row-group-cell-text {
    padding-left: 2px;
    padding-right: 2px;
}

.ag-tr-style .ag-row-group-cell-button {
    border: 1px solid transparent;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px;
}

.ag-tr-style .ag-row-group-cell-button:hover {
    border: 1px solid black;
}

.ag-tr-style .ag-row-group-empty-message {
    padding-left: 2px;
    padding-right: 2px;
    border: 1px solid transparent;
    color: grey;
}

.ag-tr-style .ag-row-group-icon {
    padding-right: 4px;
}

.ag-tr-style .ag-row-group-panel {
    border-top: 1px solid #808080;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    padding: 4px;
    background-color: #f0f0f0;
}

.ag-tr-style .ag-cell {
    padding: 8px;
}

.ag-tr-style .ag-cell-not-inline-editing {
    padding: 2px;
}

.ag-tr-style .ag-cell-range-selected-1:not(.ag-cell-focus) {
    background-color: rgba(120, 120, 120, 0.4);
}

.ag-tr-style .ag-cell-range-selected-2:not(.ag-cell-focus) {
    background-color: rgba(80, 80, 80, 0.4);
}

.ag-tr-style .ag-cell-range-selected-3:not(.ag-cell-focus) {
    background-color: rgba(40, 40, 40, 0.4);
}

.ag-tr-style .ag-cell-range-selected-4:not(.ag-cell-focus) {
    background-color: rgba(0, 0, 0, 0.4);
}

.ag-tr-style .ag-column-moving .ag-cell {
    transition: left 0.2s;
}

.ag-tr-style .ag-cell-focus {
    border: 1px solid darkgrey;
}

.ag-tr-style .ag-cell-no-focus {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #ddd;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.ag-tr-style .ag-cell-first-right-pinned {
    border-left: 1px solid black;
}

.ag-tr-style .ag-cell-last-left-pinned {
    border-right: 1px solid black;
}

.ag-tr-style .ag-cell-highlight {
    border: 1px solid darkgreen;
}

.ag-tr-style .ag-cell-highlight-animation {
    transition: border 1s;
}

.ag-tr-style .ag-cell-data-changed {
    background-color: lightblue;
}

.ag-tr-style .ag-cell-data-changed-animation {
    transition: background-color 1s;
}

.ag-tr-style .ag-cell-data-changed:not(.ag-cell-focus) {
    background-color: lightblue;
}

.ag-tr-style .ag-cell-data-changed-animation:not(.ag-cell-focus) {
    transition: background-color 1s;
}

.ag-tr-style .ag-value-change-delta {
    padding-right: 2px;
}

.ag-tr-style .ag-value-change-delta-up {
    color: darkgreen;
}

.ag-tr-style .ag-value-change-delta-down {
    color: darkred;
}

.ag-tr-style .ag-value-change-value {
    background-color: transparent;
    border-radius: 1px;
    padding-left: 1px;
    padding-right: 1px;
    transition: background-color 1s;
}

.ag-tr-style .ag-value-change-value-highlight {
    background-color: #cec;
    transition: background-color 0.1s;
}

.ag-tr-style .ag-rich-select {
    font-size: 14px;
    border: 1px solid #000;
    background-color: #f6f6f6;
}

.ag-tr-style .ag-rich-select-value {
    padding: 2px;
}

.ag-tr-style .ag-rich-select-list {
    background-color: #fff;
    border-top: 1px solid #aaa;
}

.ag-tr-style .ag-rich-select-row {
    padding: 2px;
}

.ag-tr-style .ag-rich-select-row-selected {
    background-color: powderblue;
}

.ag-tr-style .ag-header {
    background-color: #eeeeee;
    border-bottom: 1px solid #ddd;
}

.ag-tr-style .ag-no-scrolls .ag-header-container {
    background: linear-gradient(white, lightgrey);
    border-bottom: 1px solid grey;
}

.ag-tr-style .ag-header-cell {
    border-right: 1px solid #ddd;
}

.ag-tr-style .ag-header-cell-moving .ag-header-cell-label {
    opacity: 0.5;
}

.ag-tr-style .ag-header-cell-moving {
    background-color: #bebebe;
}

.ag-tr-style .ag-header-cell-moving-clone {
    border-right: 1px solid grey;
    border-left: 1px solid grey;
    background-color: rgba(220, 220, 220, 0.8);
}

.ag-tr-style .ag-header-group-cell {
    border-right: 1px solid #ddd;
}

.ag-tr-style .ag-header-group-cell-with-group {
    border-bottom: 1px solid #ddd;
}

.ag-tr-style .ag-header-group-cell-no-group {
    border-bottom: 1px solid #ddd;
}

.ag-tr-style .ag-header-cell-label {
    margin-top: 8px;
}

.ag-tr-style .ag-header-cell-text {
    padding-left: 2px;
}

.ag-tr-style .ag-header-group-cell-label {
    padding: 4px;
    padding-left: 10px;
}

.ag-tr-style .ag-header-group-text {
    margin-right: 2px;
}

.ag-tr-style .ag-header-cell-menu-button {
    padding: 2px;
    margin-top: 8px;
    border: 1px solid transparent;
    border-radius: 3px;
    box-sizing: content-box;
    /* When using bootstrap, box-sizing was set to 'border-box' */
    line-height: 0px;
    /* normal line height, a space was appearing below the menu button */
}

.ag-tr-style .ag-pinned-right-header {
    border-left: 1px solid grey;
}

.ag-tr-style .ag-header-cell-menu-button:hover {
    border: 1px solid black;
}

.ag-tr-style .ag-header-icon {
    color: maroon;
}

.ag-tr-style .ag-row-selected {
    background-color: powderblue !important;
}

.ag-tr-style .ag-body .ag-row-odd {
    background-color: #f6f6f6;
}

.ag-tr-style .ag-body .ag-row-even {
    background-color: white;
}

.ag-tr-style .ag-floating-top .ag-row {
    background-color: #f0f0f0;
}

.ag-tr-style .ag-floating-bottom .ag-row {
    background-color: #f0f0f0;
}

.ag-tr-style .ag-overlay-loading-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
}

.ag-tr-style .ag-overlay-loading-center {
    background-color: transparent;
    /* Remove border for spinner */
    /*
        border: 1px solid darkgray;
        border-radius: 10px;
        */
    padding: 10px;
}

.ag-tr-style .ag-overlay-no-rows-center {
    background-color: #ffffff;
    border: 1px solid darkgray;
    border-radius: 10px;
    padding: 10px;
}

.ag-tr-style .ag-body {
    background-color: #fafafa;
}

.ag-tr-style .ag-group-cell-entire-row {
    background-color: #aaa;
    padding: 4px;
}

.ag-tr-style .ag-footer-cell-entire-row {
    background-color: #aaa;
    padding: 4px;
}

.ag-tr-style .ag-group-cell {
    font-style: italic;
}

.ag-tr-style .ag-group-expanded {
    padding-right: 2px;
}

.ag-tr-style .ag-group-contracted {
    padding-right: 2px;
}

.ag-tr-style .ag-group-value {
    padding-right: 2px;
}

.ag-tr-style .ag-group-checkbox {
    padding-right: 2px;
}

.ag-tr-style .ag-footer-cell {
    font-style: italic;
}

.ag-tr-style .ag-menu {
    border: 1px solid #ddd;
    background-color: #f0f0f0;
    cursor: default;
    font-family: "Helvetica Neue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 14px;
    border-radius: 4px;
}

.ag-tr-style .ag-menu .ag-tab-header {
    background-color: #f8f8f8;
}

.ag-tr-style .ag-menu .ag-tab {
    padding: 6px 8px 6px 8px;
    margin: 2px 2px 0px 2px;
    display: inline-block;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}

.ag-tr-style .ag-menu .ag-tab-selected {
    background-color: #f0f0f0;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-top: 1px solid black;
}

.ag-tr-style .ag-menu-separator {
    border-top: 1px solid #d3d3d3;
}

.ag-tr-style .ag-menu-option-active {
    background-color: #BDE2E5;
}

.ag-tr-style .ag-menu-option-icon {
    padding: 2px 4px 2px 4px;
    vertical-align: middle;
}

.ag-tr-style .ag-menu-option-text {
    padding: 2px 4px 2px 4px;
    vertical-align: middle;
}

.ag-tr-style .ag-menu-option-shortcut {
    padding: 2px 2px 2px 20px;
    vertical-align: middle;
}

.ag-tr-style .ag-menu-option-popup-pointer {
    padding: 2px 4px 2px 4px;
    vertical-align: middle;
}

.ag-tr-style .ag-menu-option-disabled {
    opacity: 0.5;
}

.ag-tr-style .ag-menu-column-select-wrapper {
    margin: 2px;
}

.ag-tr-style .ag-filter-checkbox {
    position: relative;
    top: 2px;
    left: 2px;
}

.ag-tr-style .ag-filter-header-container {
    border-bottom: 1px solid lightgrey;
}

.ag-tr-style .ag-filter-apply-panel {
    border-top: 1px solid lightgrey;
    padding: 2px;
}

.ag-tr-style .ag-filter-value {
    margin-left: 4px;
}

.ag-tr-style .ag-filter-filter {
    margin-left: 4px;
    padding: 2px 5px;
    font-size: 12px;
}

.ag-tr-style .ag-selection-checkbox {
    margin-left: 4px;
    margin-right: 4px;
}

.ag-tr-style .ag-paging-panel {
    padding: 4px;
    font-size: 12px;
    text-align: center;
}

.ag-tr-style .ag-paging-button {
    margin-left: 4px;
    margin-right: 4px;
    border: 1px solid #ccc;
    padding: 6px 6px;
    border-radius: 2px;
    color: #333;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ag-tr-style .ag-paging-button[disabled] {
    opacity: 0.65;
    cursor: not-allowed;
}

@media (max-width: 480px) {
    .ag-tr-style .ag-paging-button {
        width: 35px;
    }
}

.ag-tr-style .ag-paging-page-summary-panel {
    display: inline-block;
}

.ag-tr-style .ag-paging-row-summary-panel {
    display: inline-block;
    max-width: 300px;
    float: left;
    margin: 6px 0;
}

.ag-tr-style .ag-tool-panel {
    background-color: #eee;
    border-right: 1px solid #808080;
    border-bottom: 1px solid #808080;
    border-top: 1px solid #808080;
}

.ag-tr-style .ag-status-bar {
    font-size: 12px;
    height: 20px;
    border-bottom: 1px solid #808080;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    padding: 2px;
    background-color: #f0f0f0;
}

.ag-tr-style .ag-status-bar-aggregations {
    float: right;
}

.ag-tr-style .ag-status-bar-item {
    padding-left: 10px;
}

.fa-lg {
    vertical-align: 0%;
}

.box {
    border-color: #DBDEE0;
    border-width: 1px;
    border-style: solid;
    margin: 0px 0px 30px 0px;
    float: left;
    width: 100%;
    font-size: 14px;
    background-color: #FFFFFF;
}

.box-header {
    background-color: #F4F5F5;
    color: #34383C;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 40px;
    border-bottom-color: #DBDEE0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}

.box-header i {
    color: #CED1D4;
    border-right-color: #DBDEE0;
    border-right-width: 1px;
    border-right-style: solid;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    line-height: 20px;
    text-align: center;
}

.box-title p {
    color: #7C848D;
    display: inline-block;
    margin: 0px 0px 0px 0px;
}

.box-title p + p {
    margin-left: 5px;
}

.box-icon i {
    border-right: none;
    color: inherit;
    background-color: inherit;
}

.box-icon a,
.box-icon a:link,
.box-icon a:visited {
    border-left-color: #DBDEE0;
    border-left-width: 1px;
    border-left-style: solid;
    color: #7C848D;
    text-decoration: none;
    display: inline-block;
    width: 40px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    float: left;
    background-color: transparent;
}

.box-icon a:hover,
.box-icon a:focus {
    background-color: #7C848D;
    display: inline-block;
    text-decoration: none;
    border-bottom-width: 0px;
    color: #F4F5F5;
}

/*********************************************
* Angular Styles
*********************************************/

.box-content {
    color: #000000;
    background-color: #FFFFFF;
    padding: 10px;
}

.ap-input, .ap-addon, .ap-button, .ap-text-area {
    border-radius: 0px;
}

.ap-text-area {
    resize: none;
    width: 100%;
}

.ap-copy-addon {
    cursor: pointer;
    color: white;
    background-color: #8eb4cb;
    border-color: #7da8c3;
}

.ap-container {
    padding: 30px;
}
